.top-div-sl {
  display: flex;
  justify-content: center;
}
.sl {
  padding: 0px 20px;
  overflow: auto;
}

.sl table {
  table-layout: auto;
  margin-bottom: 20px;
}

.sl td,
.sl th {
  border: 1px solid;
  overflow: hidden;
  padding: 15px 10px 10px;
  text-align: center;
  white-space: inherit;
}

@media only screen and (max-width: 900px) {
  .sl table {
    width: max-content;
  }
  .sl th,
  .sl td {
    width: unset;
  }
}
