.home-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 90px;
}
.home-page-products {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
}
.physical-products {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.list-item {
  margin: 0px 5px 5px 0px;
  cursor: pointer;
}
.list-item p {
  padding: 8px 12px;
  border-radius: 8px;
  width: max-content;
}
.list-item p:hover {
  padding: 6px 11px;
  border: 1px solid #808080;
  width: max-content;
}

.list-selected-item {
  margin: 0px 5px 5px 0px;
  cursor: pointer;
}
.list-selected-item p {
  border-radius: 8px;
  padding: 7px 11px;
  border: 1px solid #808080;
  width: max-content;
}
.selected-outer-color-sect {
  border: 1px solid #808080;
  border-radius: 50%;
  margin-right: 10px;
  padding: 3px;
}
.outer-color-sect {
  margin-right: 10px;
}
.color-sect {
  padding: 20px;
  border: 1px solid #808080;
  border-radius: 50%;
  cursor: pointer;
}
.selected-color-sect {
  padding: 17px;
  border: 1px solid #808080;
  border-radius: 50%;
  cursor: pointer;
}

/* size */
.size-sect {
  padding: 8px 15px;
  border-radius: 8px;
  margin-right: 10px;
  cursor: pointer;
}

.selected-size-sect {
  padding: 6px 13px;
  border: 1px solid #808080;
  border-radius: 8px;
  margin-right: 10px;
  cursor: pointer;
}

/* pc */

.pin-code {
  display: flex;
  flex-direction: row;
  width: 300px;
  border-radius: 7px;
  padding: 5px 0px;
}
.pin-code input {
  width: 60%;
  border: 1px solid #808080;
  border-radius: 8px;
  margin-right: 10px;
}
.pin-code input:focus {
  width: 60%;
  border: 1px solid #808080;
  border-radius: 8px;
  margin-right: 10px;
}
.pin-code button {
  width: 40%;
  padding: 8px 12px;
  border-radius: 8px;
}

/* quantity */
.pd-quantity {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.pd-quantity svg {
  margin: 0px 3px;
  font-size: 2rem;
  cursor: pointer;
}

/* connect */
.connect {
  gap: 8px;
  display: flex;
  flex-wrap: wrap;
}
.connect button {
  padding: 8px 12px;
  border-radius: 8px;
}

/* images */
.images-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.images-div button {
  margin-top: 15px;
  padding: 8px 12px;
  display: flex;
  gap: 4px;
  align-items: center;
}
.images-prop {
  width: 600px;
}
.images-prop img {
  width: 100%;
  height: 100%;
  border-radius: 0%;
  border: 1px solid #808080 !important;
}
/*  */
.home-page-products .label-value-section-sub {
  flex-direction: column;
  gap: 3px;
  margin-bottom: 12px;
}
.physical-products .label-value-section-sub {
  flex-direction: column;
  gap: 3px;
  margin-bottom: 12px;
}

.physical-products .label-value-section {
  width: 600px;
  margin: 25px 0px 10% 0px;
}
.home-page-products .label-value-section-1 {
  width: 95%;
  padding: 0px 10px;
  margin-bottom: 20px;
}
.home-page-products .label-value-section-2 {
  width: 965px;
  margin-bottom: 10%;
}

.physical-products .label-value-section-label {
  display: flex;
  align-items: center;
  width: 10%;
  font-weight: 600;
}
.physical-products .label-value-section-value {
  margin-left: 0px;
  flex-wrap: wrap;
}
.home-page-products .label-value-section-value {
  overflow: scroll;
  margin-left: 0px;
  justify-content: center;
}
/* product-=display */
.product-display {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  flex-wrap: wrap;
}
.product-display-one {
  display: flex;
  border: 1px solid #808080;
  flex-direction: column;
  padding: 10px;
  cursor: pointer;
  width: 303px;
  height: 266px;
}
.product-details {
  margin-top: 10px;
}
.product-details p {
  margin: 10px 0px;
}
.product-display img {
  width: 100%;
  height: 160px;
  border-radius: 0%;
  margin-bottom: 10px;
  object-fit: cover;
}
.products-list-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.load-more-btn button {
  width: 120px;
  margin: 20px 0px;
}
@media only screen and (max-width: 750px) {
  .physical-products {
    padding: 0px 10px;
    align-items: unset;
  }
  .physical-products .label-value-section {
    width: -webkit-fill-available;
  }
  .images-prop {
    width: 100%;
    height: 100%;
  }

  .images-prop .carousel .slide img {
    height: 400px;
  }
}

/* popup */

.image-popup {
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

.image-popup-content {
  position: relative;
  margin: 10% auto;
  padding: 20px;
  background-color: #fff;
  max-width: 600px;
}

.close-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  cursor: pointer;
}

.image-popup img {
  max-width: 100%;
  max-height: 80vh;
  display: block;
  margin: 0 auto;
}

.label-value-section-value .DraftEditor-editorContainer {
  overflow: hidden !important;
}

@media only screen and (max-width: 1024px) {
  .home-page-products .label-value-section-2 {
    width: 100%;
  }
  .home-page-products .label-value-section-1 {
    width: 100%;
  }
}
@media only screen and (max-width: 1024px) {
  .product-display-one {
    width: 147px;
    height: 264px;
  }
  .product-display {
    justify-content: center;
  }
}

/* cost-comparison */

.cost-comp {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.cost-comp-child {
  color: #808080;
  width: 145px;
  display: flex;
  height: 70px;
  text-align: center;
  font-size: 1.5rem;
  border-radius: 10px;
  background-color: transparent;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.htu {
  padding: 6px 12px;
  margin-right: 10px;
  cursor: pointer;
}

.htu-selected {
  border: 1px solid #808080;
  border-radius: 5px;
  padding: 5px 11px;
  margin-right: 10px;
}

@media only screen and (max-width: 650px) {
  .cost-comp {
    flex-wrap: wrap;
  }
}
