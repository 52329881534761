* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/*  typography */

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  font-weight: normal;
  font-family: "Roboto", sans-serif;
}

h1 {
  font-size: 1.5rem;
  color: var(--primary-color);
}

h2 {
  font-size: 1.5rem;
  color: var(--secondary-color);
}

h3 {
  font-size: 1.5rem;
  color: var(--primary-color);
}

h4 {
  font-size: 1.5rem;
  color: var(--secondary-color);
  font-weight: normal;
}

h5 {
  font-size: 1.5rem;
  color: var(--primary-color);
}

h6 {
  font-size: 1.5rem;
  color: var(--secondary-color);
}

p {
  font-size: 1.5rem;
  color: var(--secondary-color);
}

a {
  font-size: 1.5rem;
  color: #808080;
}

li {
  color: #808080;
  font-size: 1.5rem;
}

li:first-child {
  list-style: none;
}

table {
  border-collapse: collapse;
}

th,
td {
  border: none;
  font-size: 1.5rem;
  color: #808080;
  font-weight: 500;
}

/* inputs */

input[type="text"],
textarea,
input[type="tel"],
input[type="email"],
input[type="search"],
input[type="password"],
input[type="date"],
input[type="number"] {
  width: 100%;
  padding: 12px;
  border-radius: 77px;
  resize: none;
  background: transparent;
  border: 1px solid #808080;
  color: var(--primary-color);
  font-size: 1.5rem;
  font-family: "Roboto", sans-serif;
}

input[type="text"].selected {
  background-color: transparent;
}

input[type="date"] {
  text-align: center;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(0.5);
}

input[type="time"] {
  text-align: center;
}

input::placeholder {
  font-size: 1.5rem;
  font-family: "Roboto", sans-serif;
  text-align: center;
}

input[type="text"]:focus-visible,
input[type="number"]:focus-visible,
input[type="tel"]:focus-visible,
input[type="email"]:focus-visible,
input[type="search"]:focus-visible,
input[type="password"]:focus-visible,
input[type="date"]:focus-visible,
textarea:focus-visible {
  border: 1px solid #808080;
  outline: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

textarea::placeholder {
  font: 1.5rem "Roboto";
  text-align: center;
}

/* select and option */

select {
  width: 100%;
  padding: 12px;
  border-radius: 77px;
  resize: vertical;
  background: transparent;
  border: 1px solid #808080;
  color: var(--primary-color);
  font: 1.5rem "Roboto";
  /** for the dropdown indicator */
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23808080' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0rem center;
  background-size: 1em;
}

option {
  text-align: center;
}

select:focus-visible {
  border: 1px solid #808080;
  outline: none;
}

/* input styles */

.row {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.col-55 {
  display: flex;
  width: 100%;
}

.col-75 {
  float: left;
  display: flex;
  margin-top: 6px;
  flex-direction: row;
  width: 80%;
}

/* button */

button {
  /* adjust the width in code  */
  padding: 12px;
  border-radius: 77px;
  resize: vertical;
  background: transparent;
  border: 1px solid #808080;
  color: var(--primary-color);
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-size: 1.5rem;
}

/* img */

img {
  width: 27rem;
  height: 27rem;
  border-radius: 50%;
}
/* underline style */

.nav-link {
  position: relative;
  text-decoration: none;
}

.nav-link::after {
  content: "";
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #808080;
  opacity: 0;
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.nav-link.active::after,
.nav-link:visited::after {
  transform: scaleX(1);
  opacity: 1;
}

.link {
  position: relative;
  text-decoration: none;
}

.underline {
  text-decoration: none;
  border-bottom: 2px solid #808080;
}

/* scrollbar  */

::-webkit-scrollbar {
  width: 13px;
  height: 0px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background: #808080;
  border-radius: 10px;
}

/* label-value section */

.label-value-section {
  margin: 20px 0px;
  width: 500px;
}

.label-value-section-sub {
  display: flex;
  margin-bottom: 10px;
}

.label-value-section-label {
  margin-top: 0px !important;
  font-size: 1.5rem !important;
  box-sizing: unset;
  text-align: left;
  color: #808080;
  white-space: pre;
  width: 7%;
}

.label-value-section-value {
  color: #808080;
  font-size: 1.5rem !important;
  text-align: left;
  margin-left: 80px;
  display: flex;
  align-items: center;
}

.label-value-section-value input {
  padding: 3px;
  outline: none;
  border-bottom-style: solid;
  border-width: 0px 0px 1px 0px;
  border-radius: 0px;
  width: 35px;
}

.label-value-section-value input:focus-visible {
  border: none;
  border-bottom: 1px solid #808080;
}
.label-value-section-button {
  margin-bottom: 20%;
}

/* loader */

.linePreloader {
  width: 100%;
  height: 4px;
  position: fixed;
  z-index: 2;
  background: linear-gradient(to right, #808080, #808080);
  background-color: #fff;
  border-radius: 4px;
  background-size: 20%;
  background-repeat: repeat-y;
  background-position: -25% 0;
  animation: scroll 1.2s ease-in-out infinite;
}

@keyframes scroll {
  50% {
    background-size: 80%;
  }
  100% {
    background-position: 125% 0;
  }
}

/* small loader */

.roundLoader {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid #808080;
  width: 17px;
  height: 17px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 750px) {
  .search-bar {
    margin: 25px 0px !important;
  }
  .search-list {
    width: unset;
  }
  ::-webkit-scrollbar {
    width: 6px;
    height: 0px;
  }
}

@media only screen and (max-width: 620px) {
  .search-list {
    left: 77px !important;
  }
}
