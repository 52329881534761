.Headers-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  margin-top: 90px;
}
.Headers-items {
  display: flex;
  gap: 3.5rem;
  margin: 0 20px 25px;
  scroll-behavior: smooth;
}

.Headers-items a {
  display: flex;
  width: max-content;
}

.top-div {
  display: flex;
  justify-content: center;
  z-index: 3;
  position: fixed;
  width: -webkit-fill-available;
}
.top-child-div {
  width: 600px;
}

@media only screen and (max-width: 600px) {
  .sec {
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 620px) {
  .top-child-div {
    width: unset;
    padding: 0px 30px !important;
    gap: 1rem !important;
  }
  .top-div {
    display: block;
  }
}
