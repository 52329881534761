.carousel-root {
  position: relative;
  margin: 0 auto;
}

.carousel .slide {
  text-align: center;
  padding: 15px 0px;
}

.carousel .slide img {
  height: 600px;
  border-radius: 0%;
  margin-bottom: 20px;
}
.carousel.carousel-slider .control-arrow {
  top: 45%;
  height: 50px;
  font-size: 26px;
  border-radius: 5px;
  bottom: 0;
  margin-top: 0;
  padding: 5px;
  background-color: gray;
}

.carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 30px;
  background-color: gray;
  padding: 10px;
  opacity: 1 !important;
  visibility: visible !important;
  transition: opacity 0.15s ease-in-out;
}

.carousel .control-dots {
  margin: 0px 0px 10px 0px;
}
.carousel-arrow-prev {
  left: 10px;
}

.carousel-arrow-next {
  right: 30px;
}

.carousel-arrow:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
}

.carousel .carousel-status {
  display: flex;
  justify-content: center;
  text-shadow: 1px 1px 1px white;
  color: #000;
}

.carousel .carousel-status ul {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
}

.carousel .carousel-status li {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: gray;
  margin: 0 4px;
  cursor: pointer;
}

.carousel .carousel-status li.active {
  background-color: black;
}
.carousel .control-dots .dot {
  background-color: gray;
}

@media only screen and (max-width: 700px) {
  .carousel .slide img {
    height: auto;
  }
}
