@import "~@flaticon/flaticon-uicons/css/all/all";
#root {
  width: 100%;
  --primary-bg: #fff;
  --secondary-bg: #202020;
  --primary-color: #808080;
  --secondary-color: #808080;
}

html,
body {
  margin: 0;
  padding: 0;
  font-size: 62.5%;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  -webkit-print-color-adjust: exact;
  background-color: var(--primary-bg);
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

@media only screen and (max-width: 600px) {
  html,
  body {
    font-size: 65%;
  }
}
